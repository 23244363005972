body {
  background: #d3d3d3;
  font-family: 'Open Sans Condensed', sans-serif;
  color: #fff;
  margin: 0;
  padding: 0;
  transition: all 1.6s;
}
.App-header {
  text-align: center;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 0.7s;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.share-button {
  cursor: pointer;
}

.share-button:hover:not(:active) {
  opacity: 0.75;
}
.react-pdf__Page__canvas{
  width: 100% !important;
}